export const HINT_ID_PREFIX = 'amzn1.alexa.hint.';
export const MIN_SCHEDULE_SLA = 4;

export const EMPTY_STRING = '';
export const SSML_PREFIX = '<speak>';
export const SSML_SUFFIX = '</speak>';
export const TEMPLATE_PREFIX = '\\$\\{';
export const TEMPLATE_SUFFIX = '\\}';
export const TEMPLATE_PREFIX_WITHOUT_SLASH = '${';
export const TEMPLATE_SUFFIX_WITHOUT_SLASH = '}';
export const WILDCARD_REGEX = TEMPLATE_PREFIX + '.+' + TEMPLATE_SUFFIX;
export const EXACT_MATCH_REGEX = '^' + WILDCARD_REGEX + '$';
export const HINT_ID_REGEX = '^amzn1\\.alexa\\.hint\\.[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$';
export const EXPERIENCE_ID_REGEX = '^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$';
export const MCM_LINK_REGEX = '^https://mcm.amazon.com/cms/MCM-[0-9]+';

export const REFERRAL_DATA_FOLLOW_LINK_TYPE = 'ReferralDataFollowLink';
export const REFERRAL_TYPE_KEY = 'type';

export const REFERRAL_DATA_FOLLOW_LINK_TARGET_ADDRESS = 'targetAddress';
export const REFERRAL_DATA_FOLLOW_LINK_TARGET_PATH = 'targetPath';
export const REFERRAL_DATA_FOLLOW_LINK_PAYLOAD_RESOURCE_MAP = 'payloadResourceMap';

export const MEDIA_CENTRAL_IMAGE_URL_PREFIX = 'https://m.media-amazon.com/images';

export const COMMON_SUCCESS_MESSAGES = {
    VALID_IMAGE: 'This image meets the basic validation requirements.'
};

export const COMMON_ERROR_MESSAGES = {
    INVALID_HINT_ID: 'Hint ID entered is not valid',
    INVALID_EXPERIENCE_ID: 'Experience ID entered is not valid',
    INVALID_MCM_LINK: 'MCM link entered is not valid',
    INVALID_HINT_ID_WHITESPACE: 'Hint ID entered contains space(s)',
    INVALID_DATE_RANGE_TEMPLATE: 'Start date is after end date',
    INVALID_ACTIVATION_START_DATE: `Start date must be at least ${MIN_SCHEDULE_SLA} days out`,
    INVALID_TIME_RANGE: 'Start time is after end time',
    MISSING_DAY_TIME_WINDOW: 'At least one day time window must be enabled',
    MISSING_END_DATE: 'End date cannot be empty during Live(10%) and Live(50%)',
    BOTH_START_END_TIME_WINDOW_MISSING: 'A day time window must have start and end time specified',

    // Context
    SUBSCRIPTION_UPSELL_TYPE_NOT_FOUND: 'Please select a subscription upsell type%s.',

    // Impression Tracking
    INVALID_WEBLAB_URL: 'Weblab cannot be url link, just specify weblab name',
    INVALID_WEBLAB_WHITESPACE: 'Weblab name cannot contain whitespace',

    // Input String
    INPUT_STRING_TO_LONG: 'Recommended character limit exceeded.',
    INPUT_STRING_NOT_VALID_URL: 'The URL provided is malformed.',

    // Image Link
    DOMAIN_ILLUSTRATION_IMAGE_LINK_NOT_SUPPORTED_TYPE: 'The URL provided does not point to a supported image type (PNG or SVG).',
    DOMAIN_ILLUSTRATION_IMAGE_SIZE_TOO_LARGE: 'The image size is too large; it cannot exceed 5 MB.',
    DOMAIN_ILLUSTRATION_IMAGE_NOT_OPENABLE_HTTP_NOT_OK: 'Pinging the URL host failed. Make sure the image link is correct and publicly accessible.',
    DOMAIN_ILLUSTRATION_IMAGE_NOT_OPENABLE_DUE_TO_NETWORK_ERROR: 'Failed to validate the image due to network error. Please use a preconfigured image.',
    DOMAIN_ILLUSTRATION_LINK_CANT_BE_EMPTY: 'The custom domain illustration cannot be blank. You can use a preconfigured image.',
    BACKGROUND_IMAGE_LINK_NOT_VALID_URL: 'The URL provided is malformed. The default background image will be used.',
    BACKGROUND_IMAGE_LINK_NOT_SUPPORTED_TYPE: 'The URL provided does not point to a supported image type (PNG or SVG). The default background image will be used.',
    BACKGROUND_IMAGE_SIZE_TOO_LARGE: 'The image size is too large; it cannot exceed 5 MB. The default background image will be used.',
    BACKGROUND_IMAGE_NOT_OPENABLE_HTTP_NOT_OK: 'Pinging the URL host failed. The default background image will be used. ',
    BACKGROUND_IMAGE_NOT_OPENABLE_DUE_TO_NETWORK_ERROR: 'Failed to validate the image due to network error. The default background image will be used.',
    BACKGROUND_IMAGE_ACKNOWLEDGED_NOT_RECEIVED: 'You must provide your acknowledgement by ticking the box above.',


    // Content Variables
    DUPLICATE_REMOTE_VARIABLE: 'Remote content variable names and remote tag names must be unique.',
    MISSING_VARIABLE_NAME: 'Content variable name must be specified.',
    MISSING_VARIABLE_DES: 'Content variable description must be specified.',
    MISSING_VARIABLE_TYPE: 'Content variable type must be specified.', // UI prevents this
    SINGLE_DEDUPE_BY_CONTENT: 'Only one template variable can be selected to enable individual content-level guardrails.',
    UNIQUE_VARIABLE_NAME: 'Content variable names must be unique.',
    MISSING_VARIABLE_DESCRIPTION: 'Description is required for remote content variables.', // Duplicate
    MISSING_VARIABLE_IP: 'Invocation parameter is required for remote content variables.', // UI prevents this

    // Invocation Parameter Validation
    MISSING_IP_ENDPOINT: 'At least one endpoint for remote service must be specified.',
    MISSING_IP_SERVICE_NAME: 'AAA service name must be specified.',
    MISSING_IP_OPERATION_NAME: 'AAA operation name must be specified.',
    INVALID_IP_OPERATION_NAME_CORAL_SERIALIZATION: 'For Coral serialization AAA operation name must be GetDomainInformation',
    MISSING_IP_ROLE_ARN: 'Role ARN must be specified.',
    INVALID_KEY_MASTER_ENTRY_WITH_IAM_AUTH: 'KeyMaster encryption is not supported with IAM auth.',
    INVALID_CORAL_SERIALIZATION_WITH_IAM_AUTH: 'Coral serialization is not supported with IAM auth.',
    MISSING_AUTH_METHOD: 'AuthenticationMethod should be one of AAA/CLOUD_AUTH/IAM_AUTH.',
    MISSING_IP_CTI: 'CTI for remote service must be specified.',
    INVALID_IP_CTI: 'CTI for remote service must be in format of C/T/I.',
    INVALID_IP_URL_TEMPLATE: '%s is not a valid URL',

    // Alexa Lab metrics configuration
    INVALID_TARGETING_CONFIGURATION: 'Experience needs at least one valid targeting configuration',
    MISSING_WEBLAB: 'Valid weblab needed for Alexa Lab metrics',
    MISSING_SUGGESTED_FEATURE_INTENT: 'Suggested feature needs an intent',

    // BYOR SNS Topic Arn validation
    INVALID_ARN_BYOR_SNS_TOPIC: '%s is not a valid arn',
    MISSING_SNS_TOPIC_ARN: 'SNS Topic ARN must be specified.',

    // Journey Metadata Field Submission validation
    MISSING_JOURNEY_IDENTIFIER: 'In order to use this feature, you must provide the Journey identifier.',
    MISSING_JOURNEY_NAME: 'In order to use this feature, you must provide the Journey name.',

    MISSING_TITLE_FOR_MULTI_CLONE: 'Experience must have title for enabled locale %s.',

    // Mobile Home Card
    INVALID_IMAGE_URL_WHITESPACE: 'Image Url cannot contains whitespace',
    INVALID_RELEASE_SCHEDULE_INPUT: 'Release schedule entered is not valid, follow pattern: RELEASE_2023_01',
};

export const NOTIFICATION_ERROR_MESSAGES = {
    // Notification Content
    MISSING_TITLE_TEMPLATE: 'Notification must have title for enabled locale %s.',
    INVALID_TITLE_CONTENT_TEMPLATE: '%s, notification title contains undefined content variable.',
    MISSING_DISPLAY_CONTENT_TEMPLATE: 'Notification must have display content for enabled locale %s.',
    INVALID_DISPLAY_CONTENT_TEMPLATE: '%s, display content contains undefined content variable.',
    MISSING_CTA_BUTTON_TITLE_TEMPLATE: 'CTA button title must not be empty for enabled locale %s.',
    INVALID_CTA_BUTTON_TITLE_TEMPLATE: '%s, CTA button title contains undefined content variable.',
    MISSING_CTA_BUTTON_ACTION_TEMPLATE: 'CTA button action must not be empty for enabled locale %s.',
    INVALID_CTA_BUTTON_ACTION_TEMPLATE: '%s, CTA button action contains undefined content variable.',
    MISSING_URL_TEMPLATE: 'Notification must have url for enabled locale %s.',
    INVALID_URL_TEMPLATE: '%s, notification url contains undefined content variable.',
    MISSING_SPOKEN_CONTENT_TEMPLATE: 'Notification must have spoken content for enabled locale %s.',
    INVALID_SPOKEN_CONTENT_TEMPLATE: '%s, spoken content contains undefined content variable.',
    // Interactive notification thumbnail
    INVALID_THUMBNAIL_IMAGE_PREFIX: `Image url must start with ${MEDIA_CENTRAL_IMAGE_URL_PREFIX}.`,

    // Data Source
    MISSING_SNS: 'SNS based notification experience must have SNS topic for the enabled locale %s.',
    MISSING_BULLSEYE: 'Bullseye based notification experience must have Bullseye segment for enabled locale %s.',
    EMPTY_AUTO_CREATED_SNS_PERMISSION: 'The IAM role/user cannot be empty for the enabled locale %s. Add the IAM principle for publish permissions.',
    INVALID_AUTO_CREATED_SNS_PERMISSION_WHITESPACE: 'The IAM role/user cannot contain whitespace',
    INVALID_AUTO_CREATED_SNS_PERMISSION: 'Expected format is: arn:aws:iam::<AWS account ID>:<user|role>/<name>',

    // Basic Information
    MISSING_END_DATE: 'Bullseye based notification experience must have end date specified for enabled locale %s.'
};

export const CIF_ERROR_MESSAGES = {
    // Content
    INVALID_CONTENT: 'Experience cannot have both Simple TTS Content and Yes/No Question defined.', // UI prevents this
    INVALID_SSML_LIVE: 'For future experiences content needs to be prefixed with <speak> and suffixed with </speak>, '
        + 'but since this experience is already LIVE, do not need to make fix',
    INVALID_SSML: 'Content needs to be prefixed with <speak> and suffixed with </speak>',
    INVALID_REFERRAL_DATA: 'Referral Data payload is invalid, cannot deserialize JSON.',
    INVALID_REJECTION_REFERRAL_DATA: 'Rejection Referral Data payload is invalid, cannot deserialize JSON.',
    INVALID_SONAR_TEMPLATE_JSON_PAYLOAD: 'Sonar Template Data payload is invalid, cannot deserialize JSON.',
    INVALID_UTTERANCE_TEMPLATE: '%s, utterance contains undefined content variable.',

    // Devices
    NO_DEVICE_ENABLED: 'Experience must have enabled devices before sending for approval.',

    // Opportunity Triggers
    MISSING_TRIGGER: 'One of Intent, Category Targeting, or Publisher ID Targeting must be present.',
    INVALID_EXCLUSION_UTTERANCE: 'NLU Opportunity exclusions does not support target utterances.', // UI prevents this
    INVALID_EXCLUSION_CATEGORY: 'NLU Opportunity exclusions does not support CategoryTargeting.', // UI prevents this
    INVALID_EXCLUSION_PUBLISHER_ID: 'NLU Opportunity exclusions does not support PublisherIdTargeting.', // UI prevents this
    MISSING_EXCLUSION_SLOT: 'NLU Opportunity exclusions require a slot to exclude. ' +
            'If you are trying to use Target Publisher Data in the exclusion, you must create a slot with dummy value in the slot name. ' +
            'This a temporary fix and we are working on a better solution.',

    // Remote Tags
    MISSING_TAG_NAME: 'Remote tag must have name specified.',
    MISSING_TAG_DESCRIPTION: 'Remote tag must have description specified.',
    MISSING_TAG_IP: 'Remote tag must have invocation parameter specified.', // UI prevents this
    INCOMPLETE_CARD_CONFIGURATION: 'Complete configuration or remove the card'
};
