import { LOCALE_TO_MARKETPLACE } from '../constants/locales';
import { CIF_ERROR_MESSAGES, COMMON_ERROR_MESSAGES, EMPTY_STRING, NOTIFICATION_ERROR_MESSAGES, SSML_PREFIX, SSML_SUFFIX, WILDCARD_REGEX } from '../constants/validatorConstants';
import { isStringEmpty } from './stringAndMappingHelper';
import { CommonValidator } from './CommonValidator';
import { getFormalizedVariableName } from './CIFValidator';
import { IFlattenedExperience } from '../models/FlattenedExperience';
import { ExperienceUpdateCandidate, IContentViewAttributes } from '../models/ExperienceUpdateCandidate';
import { INotificationExperienceContent } from '../models/appDeviceExperience/NotificationExperienceContent';
import { IExperience } from '../models/Experience';
import { isDefaultActionType } from '../models/ActionType';
import { IOdysseyExperience } from '../models/v2/IOdysseyExperience';

export interface IMultiCloneExperienceMetadata {
    /**
     * title of new experience
     */
    title: string;

    /**
     * locale of new experience
     */
    locale: string;

    /**
     * marketplace of new experience
     */
    marketplace?: string;

    /**
     * content of new experience
     */
    cifContent?: IContentViewAttributes;

    notificationContent?: INotificationExperienceContent;
}

export function buildCloneExperience(experience: IFlattenedExperience, index?: number): IMultiCloneExperienceMetadata {

    return {
        title: experience.title + ` [CLONE ${(index || 0) + 1}]`,
        locale: experience.locale || 'en_US',
        marketplace: (experience.type === 'CIF' || experience.type === 'MobileHomeCard') ? experience.marketplace || 'US' : undefined,
        cifContent: experience.type === 'CIF' ? ExperienceUpdateCandidate.extractContent(experience) : undefined,
        notificationContent: experience.type !== 'CIF' ? ExperienceUpdateCandidate.extractNotificationContent(experience) : undefined
    };
}

export function transformFromMetaDataToExperience(experience: IFlattenedExperience, multiCloneExperienceMetadata: IMultiCloneExperienceMetadata, userAlias: string): IExperience | IOdysseyExperience {
    const updateCandidate = ExperienceUpdateCandidate.cloneExperience(experience);
    updateCandidate.setBasicInfo({ title: multiCloneExperienceMetadata.title });
    updateCandidate.setRegion({ marketplace: multiCloneExperienceMetadata.marketplace, locale: multiCloneExperienceMetadata.locale });

    if (multiCloneExperienceMetadata.cifContent) {
        updateCandidate.setContent(multiCloneExperienceMetadata.cifContent);

        /**
         * For referral content we have the check so that experience need to have
         * both referral content and referral data or neither
         *
         * Basically:
         *  1. If old experinece has referral content/data, and clone experience hasn't set referral content, we will remove referral data section
         *  2. If old experinece doesn't have referral content/data, and clone experience sets referral content, we will remove referral content section
         *
         * The referral content section will only be kept if both content and referral data are not empty
         */
        if (isDefaultActionType(updateCandidate.getContent().actionType) && updateCandidate.getContent().contentType === 'referral') {
            if (isSectionEmpty(updateCandidate.getContent().referralQuestionContentText) || isSectionEmpty(updateCandidate.getContent().referralData)) {
                updateCandidate.removeReferralContent();
            }
        }
    }

    if (multiCloneExperienceMetadata.notificationContent) {
        updateCandidate.setNotificationContent(multiCloneExperienceMetadata.notificationContent);
    }

    /** Remove bullseye when multi-cloning if
     *      1. Locale or marketplace is changed
     *      2. Is auto sns topic creation
     */
    if (experience.locale !== multiCloneExperienceMetadata.locale ||
        experience.marketplace !== multiCloneExperienceMetadata.marketplace ||
        experience.dataSource === 'AUTO_CREATED_SNS_TOPIC') {
        updateCandidate.removeBullseye();
    }

    /** Remove Weblab when multi-cloning if locale pair are one of:
     *      1. en-US / es-US
     *      2. en-IN / hi-IN
     *      3. fr-CA / en-CA
     * Those pairs can be concluded as multi locale mapping to same marketplace
     */
    if (experience.locale && multiCloneExperienceMetadata.locale && experience.locale !== multiCloneExperienceMetadata.locale &&
        LOCALE_TO_MARKETPLACE[experience.locale] === LOCALE_TO_MARKETPLACE[multiCloneExperienceMetadata.locale]) {
        updateCandidate.removeWeblab();
    }

    if (updateCandidate.getMobileHomeCardAbExperiment()) {
        // in case user clone the launched experience so remove launched.
        delete updateCandidate.getMobileHomeCardAbExperiment()?.launched;
    }

    if (updateCandidate.getType() === 'MobileHomeCard') {
        return updateCandidate.transformToOdysseyExperience(userAlias);
    }

    return updateCandidate.transformToExperience(userAlias);
}

export function isSectionEmpty(sectionContent?: string) {
    return !sectionContent || sectionContent === '';
}

export function transformFromMetaDataToExperiences(
    experience: IFlattenedExperience,
    multiCloneExperienceMetadataList: IMultiCloneExperienceMetadata[],
    userAlias: string): IExperience[] | IOdysseyExperience[] {

    return multiCloneExperienceMetadataList.map((md) => transformFromMetaDataToExperience(experience, md, userAlias));
}

// Bunch of simplified text validation for multi clone

export function isSpokenContentValidForMultiClone(spokenContent?: string, locale?: string) {
    if (isStringEmpty(spokenContent)) {
        throw new Error(NOTIFICATION_ERROR_MESSAGES.MISSING_SPOKEN_CONTENT_TEMPLATE.replace('%s', locale || ''));
    }
}

export function isTitleValidForMultiClone(spokenContent?: string, locale?: string) {
    if (isStringEmpty(spokenContent)) {
        throw new Error(COMMON_ERROR_MESSAGES.MISSING_TITLE_FOR_MULTI_CLONE.replace('%s', locale || ''));
    }
}

export function isDisplayContentValidForMultiClone(displayContent?: string, locale?: string) {
    if (isStringEmpty(displayContent)) {
        throw new Error(NOTIFICATION_ERROR_MESSAGES.MISSING_DISPLAY_CONTENT_TEMPLATE.replace('%s', locale || ''));
    }
}

export function isNotificationTitleValidForMultiClone(title?: string, locale?: string) {
    if (isStringEmpty(title)) {
        throw new Error(NOTIFICATION_ERROR_MESSAGES.MISSING_TITLE_TEMPLATE.replace('%s', locale || ''));
    }
}

export function isUtteranceValidForMultiClone(utterance?: string, variables?: string[]) {
    if (utterance) {
        if (utterance === ',') {
            return;
        }

        if (!utterance.startsWith(SSML_PREFIX) || !utterance.endsWith(SSML_SUFFIX)) {
            throw new Error(CIF_ERROR_MESSAGES.INVALID_SSML);
        }

        let resolvedUtterance = utterance;
        for (const variable of variables || []) {
            resolvedUtterance = resolvedUtterance.replace(new RegExp(getFormalizedVariableName(variable), 'g'), EMPTY_STRING);
        }

        if (new RegExp(WILDCARD_REGEX).test(resolvedUtterance)) {
            throw new Error(CIF_ERROR_MESSAGES.INVALID_UTTERANCE_TEMPLATE.replace('%s', resolvedUtterance));
        }

        CommonValidator.isValidSsml(utterance);
    }
}
